import React, { useEffect } from 'react'
import Lottie, { Options } from 'react-lottie';

const animationData = require('./animationData.json');
const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        progressiveLoad: true,
        className: 'lottie'
    }
};


export interface LoadingProps {
    isLoading?: boolean;
    className?: string;
    loaderDimensions?: {
        height: number | string;
        width: number | string;
    }
    bgColor?: string;
}

const Loading: React.FC<LoadingProps> = ({
    isLoading = true,
    className = "h-screen",
    loaderDimensions = {
        height: 253,
        width: 150
    },
    bgColor = "dark:bg-custom-900"
}) => {

    useEffect(() => {
        // componentDidMount
        return () => {
            // cleanup
            isLoading = false;
        }
    }, [])


    return (
        <div className={`loader w-full flex items-center justify-center ${className} ${bgColor}`}>
            <Lottie
                style={{ width: 100, height: 250 }}
                options={defaultOptions}
                height={loaderDimensions.height}
                width={loaderDimensions.width}
                isStopped={!isLoading}
            />
        </div>
    );
}

export default React.memo(Loading);